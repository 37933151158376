<template>
  <div>
    <el-row style="margin-top: 10px;padding-left: 10px">
      <per-button type="primary" @click="dialog.shopAddState = true" style="margin-bottom: 10px" per="shopList:addShop">添加店铺</per-button>
      <el-table
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :data="table.shopList"
          border
          max-height="650"
          :default-sort="{prop: 'id', order: 'ascending'}"
          style="width: 99%">
        <el-table-column prop="shopName" label="店铺名称" align="center"></el-table-column>
        <el-table-column prop="shopCity" label="所在城市" align="center"></el-table-column>
        <el-table-column prop="isValid" label="激活状态" align="center">
          <template slot-scope="scope">
            <el-switch
                @change="isValidChange(scope.row)"
                v-model="scope.row.isValid"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column width="350" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="warning" size="mini" @click="openEdit(scope.row)">编辑</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
    <shopAdd v-if="dialog.shopAddState"  :state="dialog.shopAddState" @close="close"/>
    <shopEdit v-if="dialog.shopEditState" :shop="dialog.shop" :state="dialog.shopEditState" @close="close"/>
  </div>
</template>

<script>
import shopAdd from "@/pages/parameter/shop/shop-add";
import shopEdit from "@/pages/parameter/shop/shop-edit";

export default {
  name: "shop-list",
  data() {
    return {
      tenantCrop: localStorage.getItem("tenantCrop"),
      table: {
        shopList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      dialog: {
        shopAddState: false,
        shopEditState: false,
        shop: {},
      }

    }
  },
  components: {
    shopAdd,shopEdit,
  },
  created() {
    this.queryShopList();
  },
  methods: {
    search() {
      this.table.page = 1;
      this.queryShopList();
    },
    //查询客户列表
    queryShopList: function () {
      this.$axios({
        method: "GET",
        url: "/shop/shopList",
        params: {
          page: this.table.page,
          limit: this.table.limit,
          tenantCrop: this.tenantCrop,
        }
      }).then(response => {
        this.table.total = response.data.data.total;
        this.table.shopList = response.data.data.list;
      })
    },
    isValidChange:function (value){
      this.$axios({
        method: "PUT",
        url: '/shop/updateShop',
        params: {
          "id": value.id,
          "isValid": value.isValid,
          "tenantCrop":this.tenantCrop
        }
      }).then(response => {
        let isValidState=response.data.code === 200;
        this.$message({
          message: isValidState ? "修改激活状态成功" : response.data.msg,
          type: isValidState ? "success" : 'error'
        });
      })
    },
    //打开编辑店铺
    openEdit(data) {
      this.dialog.shopEditState = true
      this.dialog.shop = data
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryShopList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryShopList()
    },
    close: function (state) {
      this.dialog.shopAddState = state;
      this.dialog.shopEditState = state;
      this.queryShopList();
    }
  }
}
</script>

<style scoped>

</style>